import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import { rhythm } from '../utils/typography';

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata;
        return (
          <div
            style={{
              display: `flex`,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: rhythm(2.5),
              marginBottom: rhythm(1.5),
            }}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              style={{
                marginRight: rhythm(1 / 2),
                marginBottom: rhythm(1 / 2),
                minWidth: 50,
                borderRadius: `100%`,
              }}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
            <div style={{ textAlign: 'center' }}>
              <small>
                Imagens e palavras por <strong>{author}</strong>.<br />
                Instagram{' '}
                <a href={`https://instagram.com/${social.instagram}`} target="_blank">
                  @{social.instagram}
                </a>
                {' · '}
                Twitter{' '}
                <a href={`https://twitter.com/${social.twitter}`} target="_blank">
                  @{social.twitter}
                </a>
              </small>
            </div>
          </div>
        );
      }}
    />
  );
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/jcbrito_avatar.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
          instagram
        }
      }
    }
  }
`;

export default Bio;
