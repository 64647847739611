import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import { rhythm, scale } from '../utils/typography';

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const siteDescription = data.site.siteMetadata.description;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} title={siteTitle} description={siteDescription}>
        <SEO
          isHomepage
          title="início"
          keywords={[`blog`, `fotografia`, `viagens`, `aventura`]}
          meta={
            posts.length > 0
              ? [
                  {
                    property: `og:image`,
                    content: `${this.props.location.origin}${
                      posts[0].node.frontmatter.featuredImage.childImageSharp.sizes.src
                    }`,
                  },
                ]
              : []
          }
        />
        <div className="postList">
          {posts.map(({ node }, index) => {
            const title = node.frontmatter.title || node.fields.slug;
            const firstPost = index === 0;

            const postStyle = firstPost ? { gridColumn: '1 / -1', marginBottom: rhythm(1.5) } : {};
            const overlayClass = firstPost ? 'postOverlayFirstPost' : 'postOverlayOthers';

            return (
              <div key={node.fields.slug} style={postStyle}>
                <div style={{ position: 'relative' }}>
                  {firstPost && (
                    <Img sizes={node.frontmatter.featuredImage.childImageSharp.sizes} />
                  )}
                  {!firstPost && (
                    <div style={{ maxHeight: '250px', overflow: 'hidden' }}>
                      <Img sizes={node.frontmatter.featuredImage.childImageSharp.sizes} />
                    </div>
                  )}
                  <div className={`postOverlayTitle ${overlayClass}`}>
                    <h3
                      style={{
                        ...scale(firstPost ? 1.25 : 1 / 2),
                        marginTop: 0,
                        marginBottom: rhythm(1 / 2),
                      }}
                    >
                      <Link
                        style={{
                          boxShadow: `none`,
                          color: `white`,
                        }}
                        to={node.fields.slug}
                      >
                        {title}
                      </Link>
                    </h3>
                    <small>{node.frontmatter.date}</small>
                  </div>
                </div>
                <p
                  style={{ marginTop: rhythm(1 / 2), marginBottom: rhythm(1 / 8) }}
                  dangerouslySetInnerHTML={{ __html: node.excerpt }}
                />
                <div style={{ marginTop: 0, marginBottom: 0 }}>
                  <Link to={node.fields.slug}>
                    <small>Continuar a ler →</small>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
        <Bio />
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "D \\d\\e MMMM, YYYY", locale: "pt")
            title
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 900, quality: 90) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;
